import { productionOrderChangeService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { messageError, translation } from '@/utils';
import { Message } from 'element-ui';
import { ElForm } from 'element-ui/types/form';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class Reject extends mixins(DialogMixin) {
  @Prop({ required: true, type: Number })
  public changeOrderId: number = 0;

  public form: { refuseReason: string } = {
    refuseReason: ''
  };

  public formRules = {
    refuseReason: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value || value === 0) {
            callback(new Error(translation('orderChange.inputRefuseReason')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ]
  };

  public created(): void {
    this.title = 'orderChange.reviewRejected';
  }

  public submit(): void {
    this.setLoading(true);
    (this.$refs.form as ElForm).validate((valid: boolean) => {
      if (valid) {
        const params: { id: number; refuseReason: string } = {
          id: this.changeOrderId,
          refuseReason: this.form.refuseReason
        };
        productionOrderChangeService
          .reject(params)
          .then(() => {
            this.$emit('goBack');
            Message.success(translation('operationRes.operationSuccess'));
            this.closeDialog();
          })
          .catch(error => {
            messageError(error);
          })
          .finally(() => {
            this.setLoading(false);
          });
      } else {
        this.setLoading(false);
      }
    });
  }

  public openDialog(): void {
    this.form.refuseReason = '';
  }
}

import { render, staticRenderFns } from "./production-order-change-detail.vue?vue&type=template&id=56918d27&scoped=true&"
import script from "./production-order-change-detail.ts?vue&type=script&lang=ts&"
export * from "./production-order-change-detail.ts?vue&type=script&lang=ts&"
import style0 from "./production-order-change-detail.scoped.scss?vue&type=style&index=0&id=56918d27&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56918d27",
  null
  
)

export default component.exports
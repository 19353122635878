import { ChangeTypeEnum } from '@/resource/enum/changeType';
import { ProductionOrderChangeDetailResource, ProductionOrderChangeResource } from '@/resource/model';
import { PagingMixin } from './../../../../mixins/paging';
import { mixins } from 'vue-class-component';
import Reject from './reject/reject.vue';
import { Component } from 'vue-property-decorator';
import productionOrderChangeService from '@/api/production-management/production-order-change';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { OrderChangeStatusEnum } from '@/resource/enum';
import { messageError, showWarningConfirm, translation } from '@/utils';
import { Message } from 'element-ui';

@Component({
  name: 'ProductionOrderChangeDetail',
  components: {
    Reject
  }
})
export default class ProductionOrderChangeDetail extends mixins(PagingMixin) {
  /**
   * 按钮loading
   */
  public btnLoading = false;

  /**
   * 驳回弹窗
   */
  public rejectVisible: boolean = false;

  /**
   * 单据ID
   */
  public id: number = Number(this.$route.query.id);

  /**
   * 单据基本信息
   */
  public orderChange: ProductionOrderChangeResource | null = null;

  /**
   * 变更明细
   */
  public detailList: Array<ProductionOrderChangeDetailResource> = [];

  /**
   * 表格配置项
   */
  public tableOption: OsTableOption<ProductionOrderChangeDetailResource> = {
    loading: false,
    data: [],
    fit: true,
    size: 'mini',
    border: true
    // defaultSort: { prop: 'createTime', order: 'descending' }
  };

  /**
   * 表格列配置
   */
  public tableColumnOptions: Array<OsTableColumn<ProductionOrderChangeDetailResource>> = [
    {
      prop: 'orderItemCode',
      label: 'orderProduct.itemCode',
      minWidth: '180px',
      showOverflowTooltip: true,
      fixed: true,
      formatter: (row: Object): any => {
        const detail = row as ProductionOrderChangeDetailResource;

        return detail.orderItemCode;
      }
    },
    {
      prop: 'type',
      label: 'orderChangeDetail.changeType',
      minWidth: '120px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'platformProductName',
      label: 'orderProduct.platformProductName',
      minWidth: '300px',
      showOverflowTooltip: true
    },
    {
      prop: 'backendCrafts',
      label: 'orderProduct.backendCrafts',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'count',
      label: 'orderProduct.count',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'visibleSize',
      label: 'orderProduct.visibleSize',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (rowData: object): string => {
        return `${(rowData as ProductionOrderChangeDetailResource).visibleWidth} × ${
          (rowData as ProductionOrderChangeDetailResource).visibleHeight
        }`;
      }
    },
    {
      prop: 'completeSize',
      label: 'orderProduct.completeSize',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (rowData: object): string => {
        return `${(rowData as ProductionOrderChangeDetailResource).finishWidth} × ${
          (rowData as ProductionOrderChangeDetailResource).finishHeight
        }`;
      }
    },
    {
      prop: 'prepressPicture',
      label: 'orderProduct.prepressPicture',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressRemark',
      label: 'orderProduct.prepressDescription',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'orderProduct.remark',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'requiredDeliveryTime',
      label: 'orderProduct.requiredDeliveryTime',
      minWidth: '300px',
      showOverflowTooltip: true
    },
    {
      prop: 'requiredArriveTime',
      label: 'orderProduct.requiredArriveTime',
      minWidth: '300px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressFlag',
      label: 'orderProduct.prepressFlag',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'printingFlag',
      label: 'orderProduct.printingFlag',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'backendFlag',
      label: 'orderProduct.backendFlag',
      minWidth: '150px',
      showOverflowTooltip: true
    }
  ];

  public convertFlag(flag: number): string {
    return flag === 1 ? 'el-icon-check' : 'el-icon-close';
  }

  public activated(): void {
    if (!isNaN(this.id) && this.id !== Number(this.$route.query.id)) {
      this.id = Number(this.$route.query.id);
      this.initReceiptData(this.id);
    }
  }

  public created(): void {
    if (!isNaN(this.id)) {
      this.initReceiptData(this.id);
    }
  }

  /**
   * 根据状态获取i18n key
   * @param status 状态值
   * @returns
   */
  public getStatusI18Key(status: OrderChangeStatusEnum): string {
    return productionOrderChangeService.getStatusI18Key(status);
  }

  /**
   * 根据状态获取i18n key
   * @param status 状态值
   * @returns
   */
  public getChangeTypeI18Key(type: ChangeTypeEnum): string {
    return productionOrderChangeService.getChangeTypeI18Key(type);
  }

  /**
   * 返回
   */
  public goBack(): void {
    this.$router.go(-1);
  }
  /**
   * 确认变更
   */
  public handleConfirm(): void {
    showWarningConfirm(translation('orderChange.submitConfirmTip'))
      .then(async () => {
        try {
          this.btnLoading = true;
          await productionOrderChangeService.confirm(this.id);
          this.orderChange!.status = 5;
          this.orderChange!.confirmTime = new Date().toString();
          Message.success(translation('operationRes.operationSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.operationCanceled'));
      })
      .finally(() => {
        this.btnLoading = false;
      });
  }

  /**
   * 初始化单据数据
   */
  public initReceiptData(id: number): void {
    productionOrderChangeService
      .getById(id)
      .then(res => {
        this.orderChange = res;
        this.tableOption.data = res.orderChangeItemVOList || [];
      })
      .catch(error => {
        messageError(error);
      });
  }

  /**
   * 展示驳回弹窗
   */
  public reject(): void {
    this.rejectVisible = true;
  }
}
